<template>
  <div class="group relative flex cursor-pointer items-center justify-between">
    <NuxtLink
      :to="menuData.linkTo"
      class="pr-2 font-manrope font-semibold hover:text-yellow-400 xl:text-lg"
      :class="{ 'text-gray-500': route.path === menuData.linkTo }"
      @click="handleClick(menuData.linkTo)"
    >
      {{ menuData.title }}
    </NuxtLink>

    <div>
      <UiIcon
        v-if="menuData.isList"
        class="transform cursor-pointer opacity-70 transition-transform duration-200 group-hover:rotate-180"
        name="siteArrow"
        size="xs"
      />
    </div>

    <ul
      v-if="menuData.isList"
      class="invisible absolute z-20 flex w-max origin-top translate-y-2 transform flex-col rounded-2xl bg-white p-3 text-black opacity-0 shadow-md outline outline-2 outline-black/5 transition-all duration-200 group-hover:visible group-hover:translate-y-0 group-hover:opacity-100"
      style="top: 130%; left: 0"
    >
      <li
        v-for="item in menuData.list"
        :key="item.title"
        class="my-2 pr-2 font-manrope text-lg font-semibold transition-colors hover:text-yellow-400"
        :class="{ 'text-gray-500': route.path === item.linkTo }"
      >
        <NuxtLink :to="item.linkTo">{{ item.title }}</NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup>
defineProps({
  menuData: {
    type: Object,
    required: true,
  },
});

const { $telemetryLog } = useNuxtApp();
const route = useRoute();

const handleClick = (to) => {
  if (to === '/vzyat-v-dolg/') {
    $telemetryLog({
      event_name: 'btn_get_money_click',
    });
  }
};
</script>
