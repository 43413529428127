<template>
  <NuxtLink
    v-if="!menuData.isList"
    :to="menuData.linkTo"
    class="flex cursor-pointer flex-row justify-start gap-1.5 rounded-2xl bg-white px-5 py-4"
    @click="handleClick(menuData.linkTo)"
  >
    <div class="w-full">
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-row items-center justify-normal gap-2">
          <UiIcon
            :name="menuData.svgName"
            fill="black"
          />
          <p class="w-full font-manrope text-sm font-semibold">{{ menuData.title }}</p>
        </div>
      </div>
    </div>
  </NuxtLink>

  <div
    v-else
    class="flex cursor-pointer flex-row justify-start gap-1.5 rounded-2xl bg-white px-5 py-4"
    @click="toggleList"
  >
    <div class="w-full">
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-row items-center justify-normal gap-2">
          <UiIcon
            :name="menuData.svgName"
            fill="black"
          />
          <p class="font-manrope text-sm font-semibold">{{ menuData.title }}</p>
        </div>
        <UiIcon
          v-if="menuData.isList"
          name="siteArrow"
          size="xs"
          :class="{ 'rotate-0 transform': !showList, 'rotate-180 transform': showList }"
          :style="{ transition: 'transform 0.3s ease' }"
        />
      </div>
      <div v-if="showList && menuData.isList">
        <NuxtLink
          v-for="(item, index) in menuData.list"
          :key="index"
          class="flex flex-col px-8 pt-2 font-manrope text-sm font-semibold"
          :to="item.linkTo"
          @click="handleClick"
        >
          {{ item.title }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  menuData: {
    type: Object,
    required: true,
  },
});

const { $telemetryLog } = useNuxtApp();

const emit = defineEmits(['item-click']);

const showList = ref(false);

const handleClick = (to) => {
  if (to === '/vzyat-v-dolg/') {
    $telemetryLog({
      event_name: 'btn_get_money_click',
    });
  }

  emit('item-click');
};

const toggleList = () => {
  showList.value = !showList.value;
};
</script>
