<template>
  <header class="relative z-10 border-b-2 border-dnd_light_grey bg-white lg:rounded-b-4xl">
    <div class="customContainer flex items-center justify-between p-4 lg:h-[112px]">
      <NuxtLink
        class="w-[120px] lg:w-[168px]"
        to="/"
      >
        <img
          class="pointer-events-none w-full select-none"
          src="assets/icons/pvLogo.svg"
          alt="Простой вопрос"
          width="120"
          height="40"
          sizes="120"
        />
      </NuxtLink>
      <div class="flex flex-row items-center gap-10">
        <a
          href="tel:88007005965"
          class="hidden flex-col items-center xl:flex"
        >
          <p class="font-manrope text-xl font-semibold md:text-sm xl:text-xl">8 (800) 700-59-65</p>
          <p class="font-manrope text-xs font-semibold opacity-50 xl:text-sm">
            Телефон (звонок по РФ бесплатный)
          </p>
        </a>
        <div class="flex gap-1.5 lg:hidden">
          <UiButton
            square
            class="!rounded-md"
          >
            <div
              class="flex gap-10"
              @click="toggleModalCallOrder"
            >
              <UiIcon name="phone" />
            </div>
          </UiButton>
          <NuxtLink
            to="https://t.me/prostoyvopros_bot"
            target="_blank"
            @click="handleMetricButtonClick"
          >
            <UiButton
              square
              class="!rounded-md"
              type="secondary"
            >
              <UiIcon name="siteTelegram" />
            </UiButton>
          </NuxtLink>
          <UiButton
            square
            class="!rounded-md"
            type="secondary"
            @click="togglePopup"
          >
            <UiIcon name="siteMenu" />
          </UiButton>
        </div>
        <div class="hidden items-center justify-center gap-1.5 lg:flex">
          <div @click="showModalCallOrder = true">
            <UiButton
              class="customButtonSize !rounded-lg !shadow-none"
              @click="toggleModalCallOrder"
            >
              <div class="flex gap-2">
                <UiIcon name="phone" />
                <p class="font-manrope text-base font-bold tracking-wide">Заказать звонок</p>
              </div>
            </UiButton>
          </div>
          <NuxtLink
            to="https://t.me/prostoyvopros_bot"
            target="_blank"
            @click="handleMetricButtonClick"
          >
            <UiButton
              class="customButtonSize !rounded-lg !bg-dnd_light_grey hover:!bg-ui_mid_grey"
              type="secondary"
            >
              <UiIcon name="siteTelegram" />
              <p class="font-manrope text-base font-bold tracking-wide">Написать нам</p>
            </UiButton>
          </NuxtLink>
          <div @click="goToLk">
            <UiButton
              class="customButtonSize !rounded-lg"
              type="secondary"
            >
              <p class="font-manrope text-base font-bold tracking-wide">Личный кабинет</p>
            </UiButton>
          </div>
        </div>
      </div>
    </div>

    <div class="z-[9999]">
      <LazyUiPopup
        :is-open="isPopupOpen"
        title="Меню"
        @close="togglePopup"
      >
        <div class="flex flex-col gap-2 pt-8">
          <MenuItem
            v-for="item in menuItemData"
            :key="item.title"
            :menu-data="item"
            @item-click="handleMenuItemClick"
          />
          <div @click="goToLk">
            <UiButton class="customButtonSize">
              <p class="font-manrope text-base font-bold tracking-wide">Личный кабинет</p>
            </UiButton>
          </div>
        </div>
      </LazyUiPopup>
    </div>

    <hr class="hidden w-full lg:block" />
    <div
      class="customContainer hidden h-[72px] justify-between gap-1 p-6 px-10 lg:flex 2xl:gap-10 2xl:px-[120px]"
    >
      <DropDownList
        v-for="item in menuItemData"
        :key="item.title"
        :menu-data="item"
        class=""
      />
    </div>

    <LazyUiModal
      :open="isModalCallOrder"
      title="Позвоните нам"
      size="md"
      @close="toggleModalCallOrder"
    >
      <div class="flex flex-col text-xl text-black">
        <div class="flex flex-col items-center justify-center gap-2">
          <div class="flex flex-row items-center gap-2">
            <div class="rounded-lg bg-yellow-300 p-2 text-black">
              <UiIcon name="phone" />
            </div>
            <a
              href="tel:88007005965"
              class="md:text-3xl"
            >
              8 (800) 700-59-65
            </a>
          </div>
          <div class="text-black/50 sm:text-base">(звонок бесплатный)</div>
        </div>

        <div class="m-2 flex flex-row items-center gap-3">
          <hr class="w-full" />
          <span class="text-sm">или</span>
          <hr class="w-full" />
        </div>
        <div class="flex w-full flex-col items-center justify-center gap-4">
          <div class="text-xl md:text-3xl lg:text-2xl">Заказать звонок</div>
          <div class="flex w-full flex-col gap-2">
            <UiInput
              v-model="paymentStore.paymentData.name"
              title="Имя"
              placeholder="Имя"
              data-maska="Z"
              data-maska-tokens="{'Z': { 'pattern': '[а-яА-Я- ]', 'repeated': true } }"
              class="w-full"
              :error="$v.name.$error"
            />
            <UiInput
              v-model="paymentStore.paymentData.phone"
              title="Номер телефона"
              placeholder="+7(999)999-99-99"
              data-maska="+7 (9##) ### ##-##"
              class="w-full"
              :error="$v.phone.$error"
            />
          </div>
          <div class="felx-row-1 flex w-full justify-items-start pl-2 text-xs">
            <UiCheckBox v-model="isCheckBoxEnable" />
            <span class="text-sm">
              Я даю согласие на
              <a
                class="cursor-pointer text-sm italic text-dnd_black"
                href="https://api.prostoyvopros.ru/v1/file/1716968442170_.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                обработку и передачу персональных данных
              </a>
            </span>
          </div>
          <div class="w-full">
            <UiButton
              :loading="isLoading"
              type="primary"
              class="xl:text-3xl"
              :disabled="!isCheckBoxEnable || isLoading"
              @click="callSubmit"
            >
              Заказать звонок
            </UiButton>
          </div>
        </div>
      </div>
    </LazyUiModal>
    <LazyUiModal
      :open="isModalAfterCallOrder"
      size="lg"
      title="Позвоните нам"
      @close="toggleModalCallOrder"
    >
      <div class="flex flex-col gap-6 p-10">
        <p class="text-3xl font-bold">Спасибо за обращение в нашу компанию!</p>
        <p class="text-2xl font-medium">Ваша заявка успешно принята</p>
        <p class="font-bold">В течение 15 минут с Вами свяжется наш специалист</p>
      </div>
    </LazyUiModal>
  </header>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { callOrderRules } from '~/helpers/validator.js';
import getPageQs from '~/helpers/getPageQs.js';
import MenuItem from './MenuItem.vue';

const { $api, $metrika } = useNuxtApp();
const notificationStore = useNotieStore();
const paymentStore = usePaymentStore();
const route = useRoute();
const $v = useVuelidate(callOrderRules, paymentStore.paymentData);

const menuItemData = [
  {
    title: 'Главная',
    svgName: 'house',
    linkTo: '/',
  },
  {
    title: 'Получить деньги',
    svgName: 'plusSquare',
    linkTo: '/vzyat-v-dolg/',
  },
  {
    title: 'Погасить займ',
    svgName: 'stickers',
    linkTo: '/sposobyi-oplatyi/',
  },
  {
    title: 'О нас',
    svgName: 'chatDots',
    isList: true,
    list: [
      {
        title: 'Документы',
        linkTo: '/documents/',
      },
      {
        title: 'Блог',
        linkTo: '/blog/',
      },
    ],
  },
  {
    title: 'Официальное обращение',
    linkTo: '/appeal/',
    svgName: 'phone',
  },
  {
    title: 'Продукты',
    svgName: 'creditCard',
    isList: true,
    list: [
      {
        title: 'Займы по суммам',
        linkTo: '/zajmy-po-summam/',
      },
      {
        title: 'Займы по городам',
        linkTo: '/zajmy-po-gorodam/',
      },
      {
        title: 'Виды займов',
        linkTo: '/vidy-zajmov/',
      },
    ],
  },
  {
    title: 'Пожаловаться',
    svgName: 'warning',
    linkTo: '/complaint/',
  },
];

const isCheckBoxEnable = ref(false);
const isLoading = ref(false);
const isPopupOpen = ref(false);
const isModalCallOrder = ref(false);
const isModalAfterCallOrder = ref(false);

const handleMetricButtonClick = () => {
  $metrika.reachGoal('clickTelegramBot');
};

const toggleModalCallOrder = () => {
  isModalCallOrder.value = !isModalCallOrder.value;
  isModalAfterCallOrder.value = false;
};

const togglePopup = () => {
  isPopupOpen.value = !isPopupOpen.value;
};

const handleMenuItemClick = () => {
  isPopupOpen.value = false;
};

const callSubmit = async () => {
  try {
    isLoading.value = true;
    $v.value.$touch();

    if ($v.value.$invalid) {
      if ($v.value.name.$invalid) {
        notificationStore.addNotification({
          type: 'warning',
          message: 'Введите имя',
        });
      } else if ($v.value.phone.$invalid) {
        notificationStore.addNotification({
          type: 'warning',
          message: 'Введите номер телефона',
        });
      }
      return;
    }

    const data = {
      summ: 0,
      phone: paymentStore.paymentData.phone.replace(/[^0-9]/g, ''),
      region: 'Санкт-Петербург',
      city: 'Санкт-Петербург',
      email: 'noemail@mail.com',
      fio: paymentStore.paymentData.name,
      uid: '1',
      offer: '4',
    };
    try {
      data.wm_id = 'prostoyvoprosru';
      $metrika.reachGoal('obrzvonok');
      await $api.web().addLoanApplication(data);
      isModalAfterCallOrder.value = true;
      name.value = '';
      phone.value = '';
      $v.value.$reset();
    } catch (error) {
      console.log(Error, error);
    }
  } finally {
    isLoading.value = false;
  }
};

const goToLk = () => {
  const pageQs = getPageQs();
  const query =
    Object.keys(route.query).length > 0 ? { ...route.query, email_id: pageQs.email_id } : pageQs;
  const referrerCookie = useCookie('referrer');
  const tmSessionIdCookie = useCookie('tm_session_id');
  const transferQuery = {
    ...query,
    referrer: referrerCookie.value ?? '',
    tm_session_id: tmSessionIdCookie.value,
  };
  const queryString = new URLSearchParams(transferQuery).toString();

  navigateTo(`https://lk.prostoyvopros.ru?${queryString}`, { external: true });
};
</script>

<style scoped>
.customButtonSize {
  @apply h-[64px];
}
</style>
